import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
//import { Configuration,  PublicClientApplication } from "@azure/msal-browser";
import {
  Configuration,
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  InteractionType,
} from '@azure/msal-browser';
import _Debug from './Helper/Debug';

//const envclientId:string = process.env.REACT_APP_CLIENT_ID || '';

function LoadingComponent() {
  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>Authentication in progress...</div>
  );
}

function ErrorComponent({ error }: any) {
  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      An Error Occurred. Please try opening the portal in a new tab.
      <br />
      <br /> Error: {error}
    </div>
  );
}

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: 'b8a6e44a-6116-4cc9-96e9-045fd9a17c04',
    authority: 'https://login.microsoftonline.com/9f248767-8e1a-42f3-836f-c092ab95ff70/',
    redirectUri: window.location.href.toString().split('#')[0],
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const msalInstance = new PublicClientApplication(configuration);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

let active = false;

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;

    msalInstance.setActiveAccount(account);

    active = true;
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{ scopes: ['openid', 'profile'] }}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <App pca={msalInstance} />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
