import React, { useState, useEffect } from 'react';
import { msalInstance } from '..';
import _Debug from '../Helper/Debug';
import { hasOneDriveAccess, fetchOneDrive } from './useOneDrive';

import saveToOneDrive from '../Helper/saveToOneDrive';

const prefFileName = 'CDUPortalPreferences.json';

async function getPreferences() {
  const hasAccess = await hasOneDriveAccess();
  if (hasAccess) {
    try {
      let results: any = await fetchOneDrive(prefFileName);

      let data = await fetch(results['@microsoft.graph.downloadUrl']);

      let returnData = await data.json();

      //console.log({returnData});

      return returnData;
    } catch (err) {
      //console.log('Fetch Prefs Error',err);
      return null;
    }
  } else {
    console.log('Fetch Preferences error ');
    return null;
  }
}

async function savePreferences(content: any) {
  const hasAccess = await hasOneDriveAccess();

  if (hasAccess) {
    try {
      //console.log("saving preferences to od");
      saveToOneDrive(prefFileName, content);
    } catch (err) {
      console.log(err);
    }
  }
}

export default function usePreferences(): [any, boolean, (content: any) => void] {
  const [preferenceValue, setPreferenceValue] = useState<any>(null);
  const [prefsFetched, setPrefsFetched] = useState(false);

  //console.log(preferenceName);

  async function handleSavePreferences(content: any) {
    await setPreferenceValue([...content]);
    savePreferences(content);
    //setPrefsFetched(true);
  }

  async function Init() {
    const preferences = await getPreferences();
    if (preferences) {
      setPreferenceValue(preferences);
      setPrefsFetched(true);
    }
  }

  useEffect(() => {
    Init();
    return () => {};
  }, []);

  return [preferenceValue, prefsFetched, handleSavePreferences];
}
