import {
  Persona,
  PersonaSize,
  IPersonaStyles,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  IHoverCard,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Callout,
} from '@fluentui/react';
import React, { useState, useEffect, useRef } from 'react';

import { msalInstance } from '..';
import _Debug from '../Helper/Debug';
import './UserProfile.css';

//const personaStyles: Partial<IPersonaStyles> = { primaryText:{color:'white'}};

export default function UserPofile() {
  const [userDetails, setUserDetails] = useState<any>();
  const [calloutVisible, setCalloutVisible] = useState(false);
  const [confirmLogout, setConfirmLogout] = useState(false);
  //const user = msalInstance.getActiveAccount();

  //const [cardVisible, setCardVisible] = useState(false);

  const hoverCard = useRef<IHoverCard>(null);

  async function fetchUserInfo() {
    let result = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read', 'Directory.AccessAsUser.All'],
    });

    if (result.accessToken) {
      var headers = new Headers();
      var bearer = 'Bearer ' + result.accessToken;
      headers.append('Authorization', bearer);

      var options = {
        method: 'GET',
        headers: headers,
      };

      const userGraph = await fetch(
        'https://graph.microsoft.com/v1.0/me?$select=displayName,givenName,surname,userPrincipalName,mail,mobilePhone,id,businessPhone,companyName,department',
        options,
      );
      const userFetchedDetails = await userGraph.json();

      const photo = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', options);
      const pBlob = await photo.blob();

      const photoUrl = window.URL || window.webkitURL;
      const photoBlobUrl = photoUrl.createObjectURL(pBlob);

      const userInfo = {
        displayName: userFetchedDetails?.displayName,
        givenName: userFetchedDetails?.givenName,
        surname: userFetchedDetails?.surname,
        userPrincipalName: userFetchedDetails?.userPrincipalName,
        initials:
          (userFetchedDetails?.givenName ? userFetchedDetails?.givenName?.substring(0, 1) : '') +
          (userFetchedDetails?.surname ? userFetchedDetails?.surname?.substring(0, 1) : ''),
        mail: userFetchedDetails?.mail,
        mobilePhone: userFetchedDetails?.mobilePhone,
        id: userFetchedDetails?.id,
        businessPhones: userFetchedDetails?.businessPhones,
        photo: photoBlobUrl,
        companyName: userFetchedDetails?.companyName || '',
        department: userFetchedDetails?.department || '',
      };

      _Debug('Users Profile Details ', userInfo);

      setUserDetails(userInfo);
    }
  }

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const onRenderPlainCard = () => {
    return (
      <div className="cduProfileCardContent">
        <div className="cduProfileCardHeader">
          <div className="title">{userDetails?.companyName || userDetails?.department}</div>
          <DefaultButton
            className="signOutButton"
            onClick={() => {
              setConfirmLogout(true);
              //msalInstance.logoutRedirect()
            }}
          >
            Sign Out
          </DefaultButton>

          <Dialog
            hidden={!confirmLogout}
            onDismiss={() => {
              setConfirmLogout(false);
            }}
            modalProps={{ isBlocking: true }}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Sign Out?',
              subText: 'Are you sure you want to sign out?',
            }}
          >
            <DialogFooter>
              <PrimaryButton
                onClick={() => {
                  msalInstance.logoutRedirect();
                }}
              >
                Sign Out
              </PrimaryButton>
            </DialogFooter>
          </Dialog>
        </div>
        <div className="cduProfileCardInfo">
          <Persona
            className="cduUserPersona"
            size={PersonaSize.size72}
            //styles={personaStyles}
            imageInitials={userDetails?.initials}
            text={userDetails?.displayName}
            secondaryText={userDetails?.userPrincipalName}
            onRenderSecondaryText={() => {
              return (
                <div>
                  {userDetails?.userPrincipalName}
                  <br />
                  <a
                    className="profileLink"
                    href="https://myaccount.microsoft.com/?ref=MeControl"
                    target="_blank"
                  >
                    View Account
                  </a>
                  <a
                    className="profileLink"
                    href="https://charlesdarwinuni-my.sharepoint.com/person.aspx"
                    target="_blank"
                  >
                    My Office Profile
                  </a>
                </div>
              );
            }}
            showInitialsUntilImageLoads
            imageUrl={userDetails?.photo}
            hidePersonaDetails={false}
          />
        </div>
        <div className="cduProfileCardFooter"></div>
      </div>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderPlainCard,
  };

  return (
    <div className="cduUserProfile">
      {calloutVisible && (
        <Callout
          target="#logoutProfile"
          onDismiss={() => {
            setCalloutVisible(false);
          }}
          isBeakVisible={false}
        >
          {onRenderPlainCard()}
        </Callout>
      )}

      {false && (
        <HoverCard
          type={HoverCardType.plain}
          plainCardProps={plainCardProps}
          componentRef={hoverCard}
          sticky
        ></HoverCard>
      )}
      <Persona
        id="logoutProfile"
        className="userPersona"
        size={PersonaSize.size48}
        //styles={personaStyles}
        imageInitials={userDetails?.initials}
        primaryText={userDetails?.displayName}
        onRenderSecondaryText={() => {
          return (
            <div
              className="signOut"
              onClick={() => {
                msalInstance.logoutRedirect();
              }}
            >
              Sign Out
            </div>
          );
        }}
        showInitialsUntilImageLoads
        imageUrl={userDetails?.photo}
        hidePersonaDetails={true}
        onClick={() => {
          setCalloutVisible(true);
          //console.log("Open Proifile");
        }}
      />
    </div>
  );
}
