//import { useMsal } from "@azure/msal-react";
import { useState, useEffect, useCallback } from 'react';
//import { IPublicClientApplication } from "@azure/msal-browser";
import { msalInstance } from '..';
//import { ResponseType } from "@microsoft/microsoft-graph-client";

async function fetchApps(graphEndpoint: any) {
  try {
    let result = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read', 'Directory.AccessAsUser.All'],
    });

    if (result.accessToken) {
      var headers = new Headers();
      var bearer = 'Bearer ' + result.accessToken;
      headers.append('Authorization', bearer);

      var options = {
        method: 'GET',
        headers: headers,
      };

      let results: any = await fetch(graphEndpoint, options);
      let apps = results.json();
      //let nextLink = apps['@odata.nextLink'];
      //console.log('nl',nextLink);
      //console.log('results apps',apps);

      return apps;
    }
  } catch (err) {
    console.log(err);
  }
}

export default function useApps<T>() {
  const [apps, setApps] = useState<T>();
  const [fetching, setFetching] = useState(true);

  let allApps: any = [];

  // if (!msalInstance.getActiveAccount()){
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  //}
  const fetchMyAPI = useCallback(async () => {
    try {
      setFetching(true);
      let newapps = await fetchApps(
        "https://graph.microsoft.com/v1.0/servicePrincipals?$select=appRoleAssignedTo,info,displayName,loginURL,id,notes,tags&$filter=tags/any(t:t eq 'WindowsAzureActiveDirectoryCustomSingleSignOnApplication')&$expand=appRoleAssignedTo",
      );
      //newappps = await newappps.json()

      let nextLink = newapps['@odata.nextLink'];

      newapps.value.forEach((element: any) => {
        allApps.push(element);
      });
      while (nextLink) {
        let resp = await fetchApps(nextLink);
        //console.log('NL',nextLink);
        nextLink = resp['@odata.nextLink'];
        let newApps = resp.value;
        newApps.forEach((element: any) => {
          allApps.push(element);
        });
      }
      //console.log(allApps);

      let visibleApps = allApps.filter((app: any) => {
        return app.tags?.indexOf('HideApp') < 0;
      });

      /**
       * Split the apps into chunks to performing batch checks.
       */

      let chunkedApps = chunk(visibleApps, 20);
      let validApps: any = [];

      for (let chunkArray of chunkedApps) {
        //_Debug('Chunk app role assignment',chunkArray);
        let batchCheck = await batchInAppRoleAssignments(chunkArray);

        batchCheck.forEach((element: any) => {
          validApps.push(element.id);
        });
      }

      let usersApps = visibleApps.filter((visa: any) => {
        return validApps.indexOf(visa.id) > -1;
      });

      let userBlockedApps = await _appsBlocked(usersApps);
      //console.log({userBlockedApps});

      let filtered = usersApps.filter((ua: any) => {
        return !userBlockedApps.some((uba: any) => {
          return uba === ua.id;
        });
      });

      filtered.sort((a: any, b: any) => {
        if (a.displayName.toUpperCase() < b.displayName.toUpperCase()) {
          return -1;
        }
        if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) {
          return 1;
        }
        return 0;
      });

      //console.log('Chunked Apps',chunkedApps);
      //console.log({filtered});
      //console.log('userApps',usersApps);
      setApps(filtered);
      setFetching(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  //console.log('useApps',apps)

  return [apps, fetching];
}

/**
 * Split a longer array into an array of arrays of certain length
 * @param arr Array of to split into chunks
 * @param len Number of items per array chunk
 * @returns An Array of arrays.
 */

function chunk(arr: any, len: number) {
  var chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}

/**
 * Checks the App role assignments of the applications against the users appRoleAssignments.
 * @param apps Array of applications to check App Role Assignments against.
 * @returns
 */
export async function batchInAppRoleAssignments(apps: any) {
  let $batch = { requests: <any>[] };

  apps.forEach((app: any) => {
    $batch.requests.push({
      url: '/me/appRoleAssignments?$filter=resourceId eq ' + app.id,
      method: 'GET',
      id: app.id,
    });
  });

  //console.log($batch);
  // _Debug('Performing Batch request to check user has appRoleAssignment match ',$batch);
  //console.log( JSON.stringify($batch))

  try {
    let result = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read', 'Directory.AccessAsUser.All'],
    });

    if (result.accessToken) {
      var headers = new Headers();
      var bearer = 'Bearer ' + result.accessToken;
      headers.append('Authorization', bearer);
      headers.append('Content-Type', 'application/json');
      var options = {
        method: 'POST',
        headers: headers,

        body: JSON.stringify($batch),
      };

      let results = await fetch('https://graph.microsoft.com/v1.0/$batch', options);
      let apps: any = await results.json();

      //let nextLink = apps['@odata.nextLink'];
      //console.log('nl',nextLink);
      //console.log('results apps',apps);
      //console.log('Batched Apps',results.json());

      let filteredApps = apps.responses.filter((r: any) => {
        return r.body.value.length > 0;
      });
      //console.log('Filtered Apps in Batch',filteredApps);
      return filteredApps;
      // return apps;
    }
  } catch (err) {
    console.log(err);
  }

  /*    return graphClient
        .api('/$batch')
        .version("v1.0")
        .post($batch)
        .then((res:any) =>{
          if(res){
 
  
            return res.responses.filter((r:any)=>{return r.body.value.length > 0;});
            //return res.responses.some((r)=>{return r.status == 200;});
    
          }
        });*/
}

export async function _appsBlocked(apps: any) {
  //let $batch = {requests:[]};
  let appsWithBlocks: any[] = [];
  apps.forEach((app: any) => {
    try {
      let notesJSON = JSON.parse(app.notes);
      if (notesJSON !== null && notesJSON['BlockedGroups'] !== undefined) {
        // _Debug('Checking user against blocked groups for app "'+app.displayName+'"');
        // return await _userInGroup(graphClient,notesJSON["BlockedGroups"]);
        let blockedGroups = notesJSON['BlockedGroups'];
        //console.log(blockedGroups);
        blockedGroups.forEach((bg: any, i: any) => {
          let res = { app_id: app.id + '#' + i, blockedGroup: bg };
          appsWithBlocks.push(res);
        });
      }
    } catch (err) {
      return false;
    }
  });

  //console.log('appsWithBlocks',appsWithBlocks);

  let chunkedBlockedApps = chunk(appsWithBlocks, 20);
  //console.log(chunkedBlockedApps);
  let userBlockedApps: any = [];
  for (let chunkArray of chunkedBlockedApps) {
    //_Debug('Chunk app role assignment',chunkArray);
    let batchCheck = await batchInBlockedApps(chunkArray);
    batchCheck.forEach((element: any) => {
      let app_id = element.id.substring(0, 36);
      userBlockedApps.push(app_id);
    });
  }

  //console.log("userBlockedApps",userBlockedApps);

  return userBlockedApps;
}

export async function batchInBlockedApps(groups: any) {
  let $batch = { requests: <any>[] };

  groups.forEach((app: any) => {
    $batch.requests.push({
      url: '/me/memberOf/' + app.blockedGroup,
      method: 'GET',
      id: app.app_id,
    });
  });

  //_Debug('Performing Batch request to check user is in blocked group ',$batch);

  try {
    let result = await msalInstance.acquireTokenSilent({ scopes: ['User.Read'] });

    if (result.accessToken) {
      var headers = new Headers();
      var bearer = 'Bearer ' + result.accessToken;
      headers.append('Authorization', bearer);
      headers.append('Content-Type', 'application/json');
      var options = {
        method: 'POST',
        headers: headers,

        body: JSON.stringify($batch),
      };

      let results = await fetch('https://graph.microsoft.com/v1.0/$batch', options);
      let apps: any = await results.json();

      let filteredApps = apps.responses.filter((r: any) => {
        return r.status === 200;
      });
      //console.log('Filtered Apps in Batch',filteredApps);
      return filteredApps;
      // return apps;
    }
  } catch (err) {
    console.log(err);
  }

  /* return graphClient
        .api('/$batch')
        .version("v1.0")
        .post($batch)
        .then((res:any) =>{
          if(res){
   
            return res.responses.filter((r)=>{return r.status == 200;});
    
          }
        });*/
}
