import { useState, useEffect } from 'react';
import { msalInstance } from '..';
import _Debug from '../Helper/Debug';

export async function hasOneDriveAccess() {
  let result = await msalInstance.acquireTokenSilent({
    scopes: ['User.Read', 'Files.ReadWrite'],
  });

  if (result.accessToken) {
    var headers = new Headers();
    var bearer = 'Bearer ' + result.accessToken;
    headers.append('Authorization', bearer);

    var options = {
      method: 'GET',
      headers: headers,
    };

    try {
      let results: any = await fetch('https://graph.microsoft.com/v1.0/me/drive/', options);
      // console.log(results, results.status);

      if (results.status !== 403) {
        _Debug('%cUser has One Drive Access.', 'color:green;');

        return true;
      } else {
        _Debug(
          '%cUser does NOT have One Drive Access.',
          'color:red;font-size:16px;font-weight:bolder;',
        );
        return false;
      }
    } catch (err: any) {
      _Debug(
        '%cUser does NOT have One Drive Access.',
        'color:red;font-size:16px;font-weight:bolder;',
      );
      _Debug(err);

      if (err['statusCode'] === 403) {
        _Debug(
          '%cUser does not have a valid License that provides access to One Drive.',
          'color:red;',
        );
        return false;
      } else {
        return false;
      }
    }
  }
}

export async function fetchOneDrive(fileName: string, filePath?: string) {
  try {
    let result = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read', 'Files.ReadWrite'],
    });

    if (result.accessToken) {
      var headers = new Headers();
      var bearer = 'Bearer ' + result.accessToken;
      headers.append('Authorization', bearer);

      var options = {
        method: 'GET',
        headers: headers,
      };

      const newFilePath = filePath || 'https://graph.microsoft.com/v1.0/me/drive/special/approot:/';

      const graphEndpoint: string = newFilePath + fileName;
      let results: any = await fetch(graphEndpoint, options);

      let odFile = await results.json();

      return odFile;
    }
  } catch (err) {
    console.log('Fetch one Drive Error', err);
    return new Error();
  }
}

export async function saveOneDrive(content: any, fileName?: string) {
  try {
    let result = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read', 'Files.ReadWrite'],
    });

    if (result.accessToken) {
      var headers = new Headers();
      var bearer = 'Bearer ' + result.accessToken;
      headers.append('Authorization', bearer);

      var options: any = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(content),
      };

      const newFileName = fileName || 'CDUPortalApps.json';
      //console.log(newFileName,options.body);

      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/drive/special/approot:/${newFileName}:/content`,
        options,
      );
    }
  } catch (err) {}
}

export default function useOneDrive<T>() {
  const [hasAccess, setHasAccess] = useState<any>(false);
  const [fileExists, setExists] = useState(false);
  const [backupFileExists, setBackupExists] = useState(false);
  const [oldFile, setOldFile] = useState<T>();
  const [backupFile, setBackupFile] = useState<T>();
  const [oldFileExists, setOldExists] = useState(false);
  const [odFile, setFile] = useState<T>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getBackup() {
      try {
        let fetchedBackupFile = await fetchOneDrive('CDUPortalAppsBackup.json');
        let dLoadLink = fetchedBackupFile['@microsoft.graph.downloadUrl'];
        let fileData = await fetch(dLoadLink);
        let data = await fileData.json();

        setBackupFile(data);
        setBackupExists(true);
      } catch (err: any) {
        if (err instanceof SyntaxError) {
          _Debug('%cUser Backup file corrupted or wrong format.', 'color:red;');
        }
        if (err.code == 'itemNotFound') {
          //console.error("No File Found");
          _Debug('%cUser Backup file does not exist.', 'color:red;');
        }

        setBackupFile(odFile);
        saveOneDrive(odFile, 'CDUPortalAppsBackup.json');
        setBackupExists(true);
      }
    }

    if (!backupFileExists) {
      getBackup();
    }
  }, [fileExists, backupFileExists]);

  useEffect(() => {
    /*async function setBackUp(){
            if(!backupFileExists){
                setBackupFile(odFile);
                saveOneDrive(odFile,'CDUPortalAppsBackup.json');
            }
        }*/

    async function getData() {
      setLoading(true);
      /**
       * Check if the user has access to one drive.
       */
      let hasODAccess = await hasOneDriveAccess();
      setHasAccess(hasODAccess);

      if (hasODAccess) {
        let fetchedFileOK = false;

        try {
          let file = await fetchOneDrive('CDUPortalApps.json');

          let dLoadLink = file['@microsoft.graph.downloadUrl'];
          let fileData = await fetch(dLoadLink);
          let data = await fileData.json();
          //console.log("Data",data);
          setFile(data);
          setExists(true);
          setLoading(false);
          fetchedFileOK = true;
        } catch (err) {
          if (err instanceof SyntaxError) {
            _Debug('%cCorrupted CDUPortalAppsFile ', 'color:red', err);
          }
          fetchedFileOK = false;
        }

        if (!fetchedFileOK) {
          try {
            const filePath: string =
              'https://graph.microsoft.com/v1.0/me/drive/root:/Apps/SharePoint Online Client Extensibility Web Application Principal/';

            const fileName: string = 'CDUMyApps.json';

            let file = await fetchOneDrive(fileName, filePath);
            let dLoadLink = file['@microsoft.graph.downloadUrl'];

            let fileData = await fetch(dLoadLink);
            let data = await fileData.json();

            setOldFile(data);
            setOldExists(true);
          } catch (oldFileerr: any) {
            console.log(oldFileerr.code);
            console.log('No Old File exist at all!', oldFileerr);
            if (oldFileerr instanceof SyntaxError) {
              //_Debug('%cThe ')
            }
            setExists(false);
            setLoading(false);
          }
        }
      } else {
        console.log('User Cannot access One Drive');
      }
    }

    if (!fileExists) {
      getData();
    }

    return () => {};
  }, [fileExists]);

  useEffect(() => {
    if (oldFile) {
      saveOneDrive(oldFile, 'CDUPortalApps.json');
      setFile(oldFile);
      setExists(true);
      setLoading(false);
    }

    return () => {};
  }, [oldFile]);

  return [odFile, fileExists, loading, backupFile, hasAccess];
}
