import React, { useState, useEffect } from 'react';
//import logo from './logo.svg';
import './App.css';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
//import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import UserApps from './Components/UserApps';
import EditUserApps from './Components/EditUserApps';
import StudentRedirect from './Components/StudentRedirect';
import {
  DirectionalHint,
  IconButton,
  initializeIcons,
  Label,
  PrimaryButton,
  setIconOptions,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import useApps from './Hooks/useApps';
import useOneDrive from './Hooks/useOneDrive';
import Header from './Components/Header';
import useUserRole from './Hooks/userUserRole';
import saveToOneDrive from './Helper/saveToOneDrive';
import checkForAppChanges from './Helper/checkForAppChanges';
import PasswordExpiry from './Modules/PasswordExpiry';
import PortalMessages from './Modules/PortalMessages';
import _Debug from './Helper/Debug';
import { msalInstance } from '.';
import usePreferences from './Hooks/usePreferences';
import Tour from './Components/Tour';
import 'iframe-resizer/js/iframeResizer.contentWindow.min.js';

type AppProps = {
  pca: IPublicClientApplication;
};

/*const ErrorComponent = ()=>{
  return <div>Error!</div>
}*/
function App({ pca }: AppProps) {
  const [editMode, setEditMode] = useState(false);
  const [visible, setShowHidden] = useState(false);
  const [userApps, setUserApps] = useState<any>();
  const [preferences, setPreferences] = useState<any>();
  const siteUrl = window.location.search;
  const urlParams = new URLSearchParams(siteUrl);
  const isEmbed = urlParams.get('embed');
  const isSpredirect = urlParams.get('spredirect');
  const [newPreferences, newPreferencesFetched, setNewPreferences] = usePreferences();
  const [restorePrompt, setRestorePrompt] = useState(false);
  const [odFile, fileExists, fileLoading, backupFile, hasODAccess] = useOneDrive<any>();
  const [apps, fetching] = useApps<any>();
  // define pre-condition for default pinned list
  const studentDefaultPinnedCondition = [
    'Learnline',
    'Timetables',
    'Library',
    'Reading List',
    'MyStudentInfo',
    'Campus App',
  ];
  // login user role: students / non-student
  const [userRole, userPrincipalName] = useUserRole();
  //console.log('isSpredirect', isSpredirect);
  useEffect(() => {
    
    //console.log({newPreferencesFetched});
    if (!newPreferences && preferences) {
      setNewPreferences(preferences);
    } else if (newPreferences) {
      //console.log("startEditTour",getUserPreference("startEditTour"))
    } else if (!newPreferences && !preferences && newPreferencesFetched) {
      setNewPreferences([]);
    }
  }, [newPreferences, preferences, newPreferencesFetched]);
  //useEffect(() => {
  //console.log({newPreferences});
  //}, [newPreferences])
  useEffect(() => {
    if (!fileLoading) {
      if (odFile) {
        setUserApps(odFile['values']);
        // _Debug('Users OneDrive Apps ',odFile['values']);
        _Debug('Users OneDrive Apps ', odFile['values'], () => {
          console.table(odFile['values'], ['id', 'displayName', 'loginUrl', 'pinned', 'visible']);
        });
        if (odFile['preferences']) {
          setPreferences(odFile['preferences']);
          _Debug('User Preferences. ', odFile['preferences'], () => {
            console.table(odFile['preferences']);
          });
        }
      } else if (backupFile) {
        console.log('Backup file exists');
        setRestorePrompt(true);
      } else {
        // console.log('No ODFile');
      }
    }
  }, [fileLoading, odFile, backupFile]);
  useEffect(() => {
    //console.log("hasODAccess", hasODAccess);
    if (!fileLoading && !fetching && !hasODAccess) {
      //console.log('Apps Fetched');
    }
  }, [fileLoading, fetching, hasODAccess]);
  useEffect(() => {
    if (apps && apps.length > 0 && !fetching) {
      _Debug('Fetched Apps from Server: ', apps, () => {
        console.table(apps, ['id', 'displayName', 'loginUrl', 'pinned', 'visible']);
      });
      if (userApps && apps) {
        let [appsChanged, updatedApps] = checkForAppChanges(userApps, apps);
        if (appsChanged) {
          _Debug('Updated applications : ', updatedApps, () => {
            console.table(updatedApps, ['id', 'displayName', 'loginUrl', 'pinned', 'visible']);
          });
          saveOneDrive(updatedApps);
          setUserApps([...updatedApps]);
        }
      }
      if (!userApps && apps) {
        if (!restorePrompt) {
          setUserApps([...apps]);
        }
        if (hasODAccess) {
          if (!restorePrompt && !backupFile) {
            if (userRole === 'students') {
              // if there is no app json file, create default pinned app
              let studentDefaultPinnedApps: any = [];
              // define pre-condition for default pinned list
              const pinnedAppFilterer = apps.filter((app: any) => {
                return studentDefaultPinnedCondition.includes(app.displayName);
              });
              // add pinned flag into pinned app list
              pinnedAppFilterer.forEach((subData: any) => {
                const newSub = { ...subData, pinned: true };
                studentDefaultPinnedApps.push(newSub);
              });

              // select the app list which not includes default app list
              const noPinnedApps = apps.filter((app: any) => {
                return studentDefaultPinnedCondition.includes(app.displayName) === false;
              });
              // merge the pinned app and no pinned app list
              const studentsFullApps = [...studentDefaultPinnedApps, ...noPinnedApps];
              // save app to OneDrive
              saveOneDrive([...studentsFullApps]);
              // set user app
              setUserApps([...studentsFullApps]);
            } else {
              saveOneDrive([...apps]);
            }
          }
        }
      }
      /*if(!hasODAccess && apps){
      console.log('Setting apps because no access');
      setUserApps([...apps]);
    }*/
    }
  }, [
    fetching,
    userApps,
    apps,
    hasODAccess,
    restorePrompt,
    backupFile,
    userRole,
    userPrincipalName,
  ]);
  useEffect(() => {
    initializeIcons(undefined, { disableWarnings: true });
    setIconOptions({
      disableWarnings: true,
    });
    _Debug('Logged in User Account: ', msalInstance.getActiveAccount());
    return () => {};
  }, []);
  const toggleHiddenIcons = () => {
    setShowHidden(!visible);
    //console.log('visible',visible);
  };
  const restoreFromBackup = () => {
    saveToOneDrive('CDUPortalApps.json', backupFile);
    setUserApps(backupFile['values']);
    if (backupFile['preferences']) {
      setPreferences(backupFile['preferences']);
    }
    setEditMode(false);
  };
  const displayEditMode = () => {
    setEditMode(!editMode);
  };
  const saveOneDrive = (appsToSave: any) => {
    const newFile = {
      refreshDate: new Date(),
      values: appsToSave,
      preferences: preferences,
    };
    saveToOneDrive('CDUPortalApps.json', newFile);
    saveToOneDrive('CDUPortalAppsBackup.json', newFile);
  };
  const saveEditChanges = (newApps: any) => {
    if (newApps) {
      setEditMode(false);
      saveOneDrive(newApps);
      setUserApps(newApps);
    }
  };
  const userPreferenceExists = (key: string): boolean => {
    return newPreferences.reduce((upp: any, up: any) => upp || up.key === key, false);
  };
  const getUserPreference = (key: string): string => {
    const prefExists = userPreferenceExists(key);
    let result = '';
    if (prefExists) {
      newPreferences.forEach((up: any) => {
        if (up.key === key) {
          result = up.value;
        }
      });
    }
    return result;
  };
  const updateUserPreferenceValue = (key: string, value: string) => {
    const prefExists = userPreferenceExists(key);
    let tempPrefs = newPreferences;
    if (prefExists) {
      tempPrefs.forEach((up: any) => {
        if (up.key === key) {
          up.value = value;
        }
      });
      setNewPreferences(tempPrefs);
    } else {
      tempPrefs.push({ key: key, value: value });
      setNewPreferences(tempPrefs);
    }
  };
  // console.log('Render');
  return (
    <div className="App">
      <div>
        {isEmbed === null ? <Header /> : null}
        <AuthenticatedTemplate>
          <PasswordExpiry />
          <PortalMessages />

          <div className="appsWrapper">
            {fileLoading ||
              (!fileLoading && !userApps && fetching && !restorePrompt && (
                <>
                  <Spinner label="Fetching your applications." size={SpinnerSize.large} />
                </>
              ))}

            {!editMode && userApps && !restorePrompt && (
              <>
                <div className="appsMenuContainer">
                  {hasODAccess && (
                    <IconButton
                      id="menuButton"
                      menuProps={{
                        items: [
                          {
                            key: 'pageEdit',
                            text: 'Edit',
                            iconProps: { iconName: 'PageEdit' },
                            onClick: () => displayEditMode(),
                          },
                          {
                            key: 'showHidden',
                            text: false || visible ? 'Hide' : 'Show Hidden',
                            iconProps: {
                              iconName: false || visible ? 'Hide' : 'RedEye',
                            },
                            onClick: () => toggleHiddenIcons(),
                          },
                        ],
                        directionalHintFixed: true,
                      }}
                      iconProps={{ iconName: 'Settings' }}
                      title="Edit"
                      ariaLabel="Edit"
                    ></IconButton>
                  )}
                </div>

                <UserApps showHiddenApps={visible} apps={userApps} hasODAccess={hasODAccess} />
              </>
            )}
            {restorePrompt && (
              <div style={{ textAlign: 'center', color: 'black', padding: '20px' }}>
                There is an issue retrieving your saved settings. However there appears to be a
                backup. <br /> Would you like to try and restore?
                <br />
                <br />
                <PrimaryButton
                  onClick={() => {
                    restoreFromBackup();
                    setRestorePrompt(false);
                  }}
                >
                  Restore
                </PrimaryButton>
              </div>
            )}

            {editMode && !fileLoading && (
              <EditUserApps
                apps={userApps}
                toggleEditMode={displayEditMode}
                saveEditChanges={(lst: any) => {
                  saveEditChanges(lst);
                }}
                restoreFromBackup={restoreFromBackup}
              />
            )}

            {newPreferencesFetched &&
              newPreferences &&
              getUserPreference('startEditTour') !== 'true' && (
                <>
                  <Tour
                    onDismiss={() => {
                      console.log('Tour Dismissed');
                      updateUserPreferenceValue('startEditTour', 'true');
                      //setNewPreferences({"key":"startEditTour","value":"true"});
                    }}
                    steps={[
                      {
                        target: '.searchField',
                        headline: 'Search',
                        content: 'Type in the search field to filter your applications. ',
                      },
                      {
                        target: '#menuButton',
                        headline: 'Customise your Portal experience.',
                        content:
                          "Go here to sort your applications. Pin your favorite ones and hide the ones you don't want to see. \n You can also toggle the icons you have previously hidden to make them visible.",
                        callback: () => {
                          displayEditMode();
                        },
                        //onShow:()=>{ },
                        nextText: 'Try it out (Next)',
                      },
                      {
                        //target:"."+styles.editReactSortable+"#unPinned",
                        target: '.editReactSortable#unPinned',
                        headline: 'Sort your Apps.',
                        content:
                          'Drag and Drop your applications icons to change the order they appear in. ',
                        prevCallback: () => {
                          displayEditMode();
                        },
                        delay: 50,
                      },
                      {
                        target: '.editReactSortable#pinned',
                        headline: 'Pin your favorites.',
                        content:
                          'Drag your apps into the Pinned section to pin them to the top. Or drag them to the unpinned section to unpin them. ',
                      },
                      {
                        target: '.tileMenuButton',
                        headline: 'Hide and Show.',
                        content:
                          'You can hide or show an App by clicking on the menu button on the top right of the App.',
                        //directionalHint:DirectionalHint.rightCenter
                        directionalHint: DirectionalHint.topCenter,
                      },
                      {
                        target: '#saveAndClose',
                        headline: 'All done?',
                        content:
                          'Once your finished click the Save and Close button to save your changes.',
                        callback: () => {
                          updateUserPreferenceValue('startEditTour', 'true');
                        },
                        nextText: 'Finish',
                      },
                    ]}
                  ></Tour>
                </>
              )}
          </div>
        </AuthenticatedTemplate>
      </div>
      {/** stop redirect in case of not embeded and spredirect=false */}
      {isSpredirect?.toLocaleUpperCase()!=='FALSE' && isEmbed === null ? <StudentRedirect userPrincipalName={userPrincipalName} /> : null}
    </div>
  );
}

export default App;
