/**
 *
 * @param key the name of the parameter
 * @param value the value of parameter required to return true
 * @returns boolean value
 */
export const _URLParamExists = (key: string, value: string) => {
  return new URLSearchParams(window.location.search).get(key) === value;
};

/**
 * Used to display console logs only if the url contains the '?debugPortal=true' parameter.
 */
export const _Debug = (...args: any[]) => {
  //const _debug = new URLSearchParams(window.location.search).get('debugPortal') == "true";

  const _debug = _URLParamExists('debugPortal', 'true');
  if (_debug) {
    let cb = args.slice(args.length - 1, args.length);
    if (typeof cb[0] === 'function') {
      let arr = args.slice(0, args.length - 1);
      console.log(...arr);
      cb[0].call();
    } else {
      console.log(...args);
    }
  }
  //if(_debug){
  //  console.log(...args);
  //}
};

export default _Debug;
