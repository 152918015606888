import { msalInstance } from '..';

export default async function saveToOneDrive(fileName: string, content: any) {
  try {
    let result = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read', 'Files.ReadWrite'],
    });

    if (result.accessToken) {
      var headers = new Headers();
      var bearer = 'Bearer ' + result.accessToken;
      headers.append('Authorization', bearer);

      var options: any = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(content),
      };
      const newFileName = fileName || 'CDUPortalApps.json';
      //console.log(newFileName,content)
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/drive/special/approot:/${newFileName}:/content`,
        options,
      );

      //console.log('post response',response);
    }
  } catch (err) {
    console.log('Saving Error ', err);
  }
}
