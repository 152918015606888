import { Dialog, DialogFooter, PrimaryButton, DialogType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { msalInstance } from '..';
import _Debug from '../Helper/Debug';
import './PasswordExpiry.css';

const getPasswordExpiry = async () => {
  let result = await msalInstance.acquireTokenSilent({ scopes: ['User.Read'] });

  const expireStudent = 180;
  const expireStaff = 90;
  const lessThanDays = 15;
  const staffURL = 'https://sso.cdu.edu.au/dashboard/f?p=1051';
  const studentURL = 'https://sso.cdu.edu.au/dashboard/f?p=1051';

  if (result.accessToken) {
    var headers = new Headers();
    var bearer = 'Bearer ' + result.accessToken;
    headers.append('Authorization', bearer);

    var options = {
      method: 'GET',
      headers: headers,
    };

    const userGraph = await fetch(
      'https://graph.microsoft.com/v1.0/me?$select=lastPasswordChangeDateTime,displayName,userPrincipalName',
      options,
    );
    const userFetchedDetails = await userGraph.json();

    let lastChangeDate = new Date(userFetchedDetails.lastPasswordChangeDateTime);
    let staffDiff = getDiff(expireStaff, lastChangeDate);
    let expiryInfo: IPasswordExpiry = {
      visible: staffDiff < lessThanDays,
      domain: 'staff',
      link: staffURL,
      days: staffDiff,
    };

    if (userFetchedDetails.userPrincipalName.indexOf('student') >= 0) {
      let studentDiff = getDiff(expireStudent, lastChangeDate);
      expiryInfo = {
        visible: studentDiff < lessThanDays,
        domain: 'student',
        link: studentURL,
        days: studentDiff,
      };
    }

    return expiryInfo;
  }
};

function getDiff(expireDays: number, lastChangeDate: Date) {
  const today = new Date();
  return Math.round(
    expireDays - (today.getTime() - lastChangeDate.getTime()) / (1000 * 60 * 60 * 24),
  );
}

interface IPasswordExpiry {
  visible: boolean;
  domain: string;
  link: string;
  days: number;
}

export default function PasswordExpiry() {
  const [expiry, setExpiry] = useState<IPasswordExpiry>();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getPasswordExpiry().then(res => {
      _Debug('User Password Expiry Information: ', res);
      setExpiry(res);
    });
  }, []);

  useEffect(() => {
    setVisible(expiry?.visible || false);
  }, [expiry]);

  return (
    <>
      <Dialog
        hidden={!visible}
        onDismiss={() => {
          setVisible(false);
        }}
        modalProps={{
          styles: {
            main: {
              position: 'absolute',
              right: 10,
              top: 10,
            },
          },
          isModeless: true,
          className: 'passWordExpiry',
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Your Password is about to expire.',
        }}
      >
        {expiry && expiry?.days >= 0 && (
          <p>
            Your password will expire in <b>{expiry?.days}</b> days.
          </p>
        )}
        {expiry && expiry?.days < 0 && <p>Your password has expired.</p>}

        <p>
          Go to <a href={expiry?.link}>{expiry?.link}</a> to reset your password.
        </p>
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setVisible(false);
            }}
            text="Hide"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
}
