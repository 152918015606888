import * as React from 'react';
// import { IApps } from "../Interfaces/IApp";
// import { IAppsState } from "../Interfaces/IAppsState";
// import styles from './CduMyApps.module.scss';
//import { TeachingBubble, ITeachingBubbleProps } from 'office-ui-fabric-react';
import { TeachingBubble, ITeachingBubbleProps } from '@fluentui/react/lib/TeachingBubble';
import { DirectionalHint } from '@fluentui/react';

export interface ITour {
  steps: ITourStep[];
  onDismiss?: () => void;
}

export interface ITourStep extends ITeachingBubbleProps {
  content?: string;
  onDismiss?: () => void;
  onShow?: () => void;
  callback?: () => void;
  prevCallback?: () => void;
  nextText?: string;
  prevText?: string;
  directionalHint?: DirectionalHint;
  delay?: number;
}

interface ITourState {
  currentStep: number;
}

export default class Tour extends React.Component<ITour, ITourState> {
  constructor(props: ITour) {
    super(props);
    this.state = {
      currentStep: 0,
    };
  }

  private _nextStep() {
    this.setState({ currentStep: 2 });
  }

  private step: ITourStep = {};

  public componentDidUpdate(prevProps: ITour, prevState: ITourState) {
    //console.log("Tour Updated");
    try {
      if (this.step.onShow) {
        this.step?.onShow();

        console.log('On Show Called');
      }
    } catch (err) {}
  }

  public render(): React.ReactElement<any> {
    this.step = this.props.steps[this.state.currentStep];
    let maxSteps = this.props.steps.length;
    //console.log("Step",this.step);
    //console.log('this.state.currentStep',this.state.currentStep)

    if (this.step.delay) {
      setTimeout(() => {
        this.setState({
          currentStep: this.state.currentStep,
        });
      }, this.step.delay);
    }

    //console.log(hasPrimaryButton);

    //   try{
    //       this.step.onShow();
    //    }catch(err){

    //}

    return (
      <TeachingBubble
        target={this.step.target}
        /*primaryButtonProps={
                (false || hasPrimaryButton) ? 
                    {
                        children:(false || step.primaryButtonProps.children !== undefined) ? step.primaryButtonProps.children : null,
                        onClick:() => { 
                            console.log("Primary Called",step.primaryButtonProps.onClick);
                                step.primaryButtonProps.onClick;
                                (false || this.state.currentStep < maxSteps-1)  ? {children:"Next",onClick: () => {this.setState({currentStep:this.state.currentStep+1})}} : null
                        }
                    } 
                    : null
            }*/
        //hasCloseIcon={true}
        hasCloseButton={true}
        closeButtonAriaLabel="Close"
        footerContent={this.state.currentStep + 1 + ' of ' + maxSteps}
        calloutProps={{
          preventDismissOnEvent: (ev: any) => true,
          directionalHint:
            (this.step.directionalHint !== undefined && this.step.directionalHint) ||
            DirectionalHint.rightCenter,
        }}
        //onDismiss={toggleTeachingBubbleVisible}
        hasSmallHeadline={true}
        onDismiss={() => {
          try {
            if (this.props.onDismiss) this.props.onDismiss();
          } catch (err) {}
        }}
        primaryButtonProps={
          false ||
          this.state.currentStep < maxSteps - 1 ||
          (this.step.nextText && this.step.nextText.length > 0)
            ? {
                children: this.step.nextText || 'Next',
                onClick: () => {
                  try {
                    if (this.step.callback) this.step.callback();
                  } catch (err) {}
                  this.setState({ currentStep: this.state.currentStep + 1 });
                },
              }
            : undefined
        }
        secondaryButtonProps={
          false || this.state.currentStep > 0
            ? {
                children: this.step.prevText || 'Prev',
                onClick: () => {
                  try {
                    if (this.step.prevCallback) this.step.prevCallback();
                  } catch (err) {}
                  this.setState({ currentStep: this.state.currentStep - 1 });
                },
              }
            : undefined
        }
        //primaryButtonProps={{
        //  children: 'Try it out',
        //onClick: () => {
        //    this._displayEditMode();
        //    this._userStartedTour = true;
        //    this._setUserPreference("startEditTour","true");
        //}
        //}}
        // secondaryButtonProps={exampleSecondaryButtonProps}
        //onDismiss={toggleTeachingBubbleVisible}
        headline={this.step.headline}
      >
        {this.step.content}
      </TeachingBubble>
    );
  }
}
