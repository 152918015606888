import * as React from 'react';
import { IApp } from '../Interfaces/IApp';
import { AppTile } from './AppTile';

export interface IAppList {
  value: IApp[];
  showHidden: boolean;
}

interface IAppListState {
  list: IApp[];
  showHidden: boolean;
}

export class AppList extends React.Component<IAppList, IAppListState> {
  constructor(props: IAppList) {
    super(props);

    this.state = {
      list: this.props.value,
      showHidden: this.props.showHidden,
    };
  }

  public render(): React.ReactElement<any> {
    // console.log("AppList showhidden",this.props.showHidden);
    return (
      <div className="appListContainer">
        {this.props.value.map(item => (
          <AppTile key={item.id} app={item} showHidden={this.props.showHidden}></AppTile>
        ))}
      </div>
    );
  }
}
