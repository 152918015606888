import React from 'react';

import { IApp } from '../Interfaces/IApp';

/**
 *Returns an array of applications that are new the the user since it last checked.
 */

function findWithAttr(array: any[], attr: any, value: any) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

export default function checkForAppChanges(userApps: IApp[], serverApps: any[]): [boolean, IApp[]] {
  let updatedApps: IApp[] = userApps;

  let appsChanged: boolean = false;

  //console.log({userApps});
  //console.log({serverApps});

  function addedApps(): IApp[] {
    let addedApps = serverApps.filter(serverApp => {
      //this.state.apps
      let newArr = userApps.some(app => {
        return app.id === serverApp.id;
      });

      return !newArr;
    });
    return addedApps;
  }

  function addNewApps() {
    let aApps = addedApps();
    // let apps = this._oneDriveApps;
    //console.log({aApps});
    if (aApps.length > 0)
      //_Debug("Adding new apps ",addedApps);
      appsChanged = true;
    aApps.forEach(app => {
      updatedApps.push(app);
      //console.log({updatedApps})
    });

    //this._oneDriveApps = apps;
    //this._visibleUserApps = apps;
    //this._saveOneDriveApps();
    //this._saveOneDriveAppsBackup();
  }

  /* 
  Returns an array of applications that have the user USED to have but no longer appear on Azure.
  */
  function removedApps(): IApp[] {
    let removedApps = userApps.filter(serverApp => {
      let newArr = serverApps.some(app => {
        return app.id === serverApp.id;
      });
      return !newArr;
    });
    return removedApps;
  }

  function removeApps() {
    let rApps = removedApps();
    //console.log({rApps});
    if (rApps.length > 0)
      //_Debug("Removing apps ",removedApps);
      appsChanged = true;
    rApps.forEach(app => {
      let indToRemove = findWithAttr(userApps, 'id', app.id);
      updatedApps.splice(indToRemove, 1);
    });
  }

  /**
   * Check if any of the users apps have had attributes changed.
   * Check
   * 1. Icon
   * 2. Name
   * 3. Link
   * 4. Notes
   */
  function getModifiedApps(): IApp[] {
    let modifiedApps = serverApps.filter(serverApp => {
      let newArr = userApps.some(app => {
        return (
          app.id === serverApp.id &&
          (app.displayName !== serverApp.displayName ||
            app.loginUrl !== serverApp.loginUrl ||
            app.info.logoUrl !== serverApp.info.logoUrl ||
            app.notes !== serverApp.notes)
        );
      }); //this.state.apps
      //_allFetchedApps
      return newArr;
    });
    return modifiedApps;
  }

  function updateModifiedApps() {
    let modifiedApps: IApp[] = getModifiedApps();
    modifiedApps.forEach(modApp => {
      //userApps.map((vApp)=>{
      userApps.forEach(vApp => {
        if (vApp.id === modApp.id) {
          vApp.displayName = modApp.displayName;
          vApp.loginUrl = modApp.loginUrl;
          vApp.notes = modApp.notes;
          vApp.info.logoUrl = modApp.info.logoUrl;
        }
      });
    });
    //this._saveOneDriveApps();
    //this._saveOneDriveAppsBackup();
    //this.setState({apps:this._visibleUserApps});
    return modifiedApps;
  }

  function checkForUpdates(): boolean {
    let removeApps: IApp[] = removedApps();
    let newApps: IApp[] = addedApps();
    //console.log({newApps},{removeApps});
    return removeApps.length > 0 || newApps.length > 0;
  }

  function updateApps() {
    let hasChanged = checkForUpdates();
    //_Debug("Apps Changed Status " + hasChanged);
    if (hasChanged) {
      removeApps();
      //console.log('hasCHanged',hasChanged);
      addNewApps();
    }
    let modifiedApps = getModifiedApps();
    //console.log({modifiedApps});
    if (modifiedApps.length > 0) {
      appsChanged = true;
      //_Debug("Updating Applications with changed values.",modifiedApps);
      updateModifiedApps();
      //console.log('modifiedApps',updatedModifiedApps);
    }
  }

  updateApps();

  //console.log({updatedApps});

  return [appsChanged, updatedApps];
}
