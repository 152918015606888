import { Icon, SearchBox, TextField } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { AppList } from './AppList';
import './UserApps.css';

export default function UserApps(props: any) {
  const [userApps, setUserApps] = useState<any>();
  const [visibleApps, setVisibleApps] = useState([]);
  const [hasODAccess, setHasODAccess] = useState(true);

  useEffect(() => {
    setUserApps(props.apps);
    setVisibleApps(props.apps);
    setHasODAccess(props.hasODAccess);
  }, [props.apps]);

  function searchLinks(e: any) {
    if (e) {
      e.preventDefault();
      let searchText = e.target.value;
      let filteredApps = userApps.filter((app: any) => {
        return app.displayName.toUpperCase().indexOf(searchText.toUpperCase()) >= 0;
      });
      //console.log({ filteredApps });
      setVisibleApps(filteredApps);
    } else {
      clearSearch();
    }
  }

  function clearSearch() {
    setVisibleApps(userApps);
  }

  return (
    <div className="usersAppsWrapper">
      <SearchBox
        placeholder="Search : Start typing here"
        onChange={e => {
          searchLinks(e);
        }}
        onClear={() => {
          clearSearch();
        }}
        className="searchField"
      />
      {false && (
        <TextField
          onChange={e => {
            searchLinks(e);
          }}
          className="searchField"
          placeholder="Search : Start typing here"
        ></TextField>
      )}
      {userApps && hasODAccess && (
        <div className="pinnedContainer">
          <Icon
            iconName="Pinned"
            style={{
              color: '#DDD',
              fontSize: '2em',
              position: 'absolute',
              left: '1rem',
            }}
          ></Icon>
          {visibleApps.filter((item: any) => {
            return item.pinned;
          }).length > 0 ? (
            <AppList
              value={visibleApps.filter((item: any) => {
                return item.pinned;
              })}
              showHidden={props.showHiddenApps}
            />
          ) : (
            <div className="noAppsPinned">
              No apps currently pinned. Edit your apps to pin your favorite ones to the top.
            </div>
          )}
        </div>
      )}

      {userApps && (
        <AppList
          value={visibleApps.filter((item: any) => {
            return !item.pinned;
          })}
          showHidden={props.showHiddenApps}
        ></AppList>
      )}
    </div>
  );
}
