import React from 'react';

import logo from '../Images/logo.png';
import UserPofile from './UserProfile';

export default function Header() {
  return (
    <>
      <div className="header">
        <img src={logo} className="headerLogo" alt="CDU Portal" />
        <span className="headerText">CDU Portal</span>
        <UserPofile />
      </div>
    </>
  );
}
