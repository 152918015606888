import {
  DefaultButton,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  initializeIcons,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { msalInstance } from '..';
import _Debug from '../Helper/Debug';
import './PortalMessages.css';

const getFetchOptions = async (method?: string, scopes?: string[], body?: any) => {
  let accessScopes = scopes || ['User.Read'];
  let result = await msalInstance.acquireTokenSilent({ scopes: accessScopes });
  if (result.accessToken) {
    var headers = new Headers();
    var bearer = 'Bearer ' + result.accessToken;
    headers.append('Authorization', bearer);
    headers.append('Prefer', 'HonorNonIndexedQueriesWarningMayFailRandomly');
    headers.append('Content-Type', 'application/json');
    var options = {
      method: method || 'GET',
      headers: headers,
      body: body || null,
    };

    return options;
  }
};

const getUserSharepointListItem = async () => {
  try {
    const options = await getFetchOptions('GET', ['User.Read', 'Sites.ReadWrite.All']);

    const site = 'https://graph.microsoft.com/v1.0/sites/root:/sites/portal';

    //let url = `https://graph.microsoft.com/v1.0/sites/root:/sites/portaldev:/lists/UsersMessagesStatus/items?$expand=fields($select%3Dvalue)`;
    let url = `https://graph.microsoft.com/v1.0/sites/root:/sites/portal:/lists/UsersMessagesStatus/items?$expand=fields($select%3Dvalue)`;

    //let idUrl = 'https://graph.microsoft.com/v1.0/sites/b5515172-3a67-44d2-adf6-4444e24d694b,4b480f0a-d798-4adb-8bb8-93ddd4d47536/lists/f6fc7439-2531-46d8-bca8-0cbdcba56192/items?$expand=fields($select%3Dvalue)';

    return await fetch(url, options);
  } catch (err) {
    console.log(err);
  }
};

const getPortalMessages = async () => {
  try {
    let today = new Date();
    let todayString = today.toISOString();

    const options = await getFetchOptions('GET', ['User.Read', 'Sites.ReadWrite.All']);

    let url = `https://graph.microsoft.com/v1.0/sites/root:/sites/portal:/lists/Portal%20Announcements/items?$expand=fields($select%3DTitle,Start_x0020_Date,End_x0020_Date,Content,Enabled)&$filter=fields/Start_x0020_Date le '${todayString}' and fields/End_x0020_Date ge '${todayString}' and fields/Enabled eq 'Enabled'`;

    return await fetch(url, options);
  } catch (err) {
    console.log(err);
  }
};

const updateUserSharepointListItem = async (vals: any, id: number) => {
  //let currentAccount = msalInstance.getActiveAccount();
  //console.log(currentAccount);
  if (id) {
    let updateVals = {
      Value: vals.join(','),
    };
    const options = await getFetchOptions(
      'PATCH',
      ['User.Read', 'Sites.ReadWrite.All'],
      JSON.stringify(updateVals),
    );
    //let putUrl = `https://graph.microsoft.com/v1.0/sites/root:/sites/portaldev:/lists/UsersMessagesStatus/items/${id}/fields`;
    let putUrl = `https://graph.microsoft.com/v1.0/sites/root:/sites/portal:/lists/UsersMessagesStatus/items/${id}/fields`;
    fetch(putUrl, options);
  } else {
    let currentAccount = msalInstance.getActiveAccount();

    let updateVals = {
      fields: {
        Title: currentAccount?.username,
        Value: vals.join(','),
      },
    };
    const options = await getFetchOptions(
      'POST',
      ['User.Read', 'Sites.ReadWrite.All'],
      JSON.stringify(updateVals),
    );
    //let putUrl = `https://graph.microsoft.com/v1.0/sites/root:/sites/portaldev:/lists/UsersMessagesStatus/items/`;
    let putUrl = `https://graph.microsoft.com/v1.0/sites/root:/sites/portal:/lists/UsersMessagesStatus/items/`;
    fetch(putUrl, options);
  }
};

const getUserReadMessages = async () => {
  try {
  } catch (err) {}
};

export default function PortalMessages() {
  const [visible, setVisible] = useState(false);

  const [visibleMessages, setVisibleMessages] = useState<any>();
  const [portalMessages, setPortalMessages] = useState<any>();
  const [readMessages, setReadMessages] = useState([]);
  const [usersReadMessages, setUsersReadMessages] = useState<any>();
  const [userReadID, setUserReadID] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  async function init() {
    let fetchedPortalMessages = await getPortalMessages();

    let pMessages = await fetchedPortalMessages?.json();
    //console.log("pMessages", pMessages);

    setPortalMessages(pMessages?.value);

    try {
      let userSPValues = await getUserSharepointListItem();

      let spItem = await userSPValues?.json();

      //console.log("spItem", spItem);
      if (spItem.value.length > 0) {
        //console.log(spItem?.value[0]?.id);
        //console.log(spItem?.value[0]?.fields?.Value);
        let id = spItem?.value[0]?.id;

        let values = [];

        if (spItem?.value[0]?.fields?.Value && spItem?.value[0]?.fields?.Value.length > 0)
          values = spItem?.value[0]?.fields?.Value.split(',');

        //console.log("values", values);
        setUserReadID(id);
        setUsersReadMessages(values);
      } else {
        setUsersReadMessages([]);
      }
    } catch (err) {
      // setUsersReadMessages([]);
      console.log(err);
    }
  }

  //let readMessages:any[] = [];

  /**
   * Call Init on page load only.
   */
  useEffect(() => {
    init();
  }, []);

  /**
   * When list of users read messages and the portal messages are retrieved then get the unread messages and set them into the visibleMessages variable.
   * Set visible to true to display the dialog.
   */
  useEffect(() => {
    if (usersReadMessages !== undefined && portalMessages) {
      //console.log(usersReadMessages);
      _Debug('%cUsers Read Messages.', 'color:blue;', usersReadMessages);

      _Debug('%cAll Portal Messages.', 'color:blue;', portalMessages);

      let unreadMessages = portalMessages.filter((message: any) => {
        return !usersReadMessages.some((ri: string) => {
          return ri === message.id.toString();
        });
        //return !usersReadMessages.some((ri:any) =>{return ri === message.id.toString();});
      });
      //console.log(unreadMessages);
      _Debug('%cUnread Portal Messages.', 'color:blue;', usersReadMessages);
      setVisibleMessages(unreadMessages);
      if (unreadMessages.length > 0) {
        setVisible(true);
      }
    } else {
      _Debug('No Portal Messages to display.');
    }
  }, [usersReadMessages, portalMessages]);

  function dismissMessages() {
    saveReadState();
    setVisible(false);
  }

  function prevMessage() {
    setCurrentIndex(currentIndex - 1);
  }

  function nextMessage() {
    setCurrentIndex(currentIndex + 1);
  }

  function nextMessageRead() {
    markRead();
    nextMessage();
  }

  function lastMessageClose() {
    markRead();
    dismissMessages();
  }

  function saveReadState() {
    let localCopy = [...readMessages, ...usersReadMessages];
    updateUserSharepointListItem(localCopy, userReadID);
    //console.log(localCopy);
  }

  function markRead() {
    //readMessages.push(visibleMessages[currentIndex].id.toString());
    let updatedReadMessages: any = readMessages || [];
    let currentId: string = visibleMessages[currentIndex].id;
    if (currentId) {
      updatedReadMessages.push(currentId);
    }
    setReadMessages(updatedReadMessages);
    //console.log(updatedReadMessages);
  }

  return (
    <>
      <div className="cduPortalMessages">
        {visibleMessages && visibleMessages?.length > 0 && (
          <Dialog
            onDismiss={() => {
              dismissMessages();
            }}
            hidden={!visible}
            modalProps={{
              className: 'dialog',
              isBlocking: true,
              //isModeless:true,
              isDarkOverlay: true,
              containerClassName: 'dialogContainer',
            }}
            dialogContentProps={{
              type: DialogType.close,
              title:
                visibleMessages[currentIndex].fields.Title +
                ` ( ${currentIndex + 1} of ${visibleMessages.length} )`,
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: visibleMessages[currentIndex].fields.Content,
              }}
            ></div>
            <DialogFooter>
              {currentIndex > 0 && (
                <DefaultButton
                  className="prevButton"
                  onClick={() => {
                    prevMessage();
                  }}
                  text="Prev"
                  iconProps={{
                    iconName: 'ChevronLeftSmall',
                  }}
                />
              )}

              {currentIndex + 1 < visibleMessages.length && (
                <DefaultButton
                  onClick={() => {
                    nextMessage();
                  }}
                  text="Skip (Read Later)"
                  iconProps={{
                    iconName: 'ChevronRightSmall',
                  }}
                />
              )}

              {currentIndex + 1 < visibleMessages.length && (
                <PrimaryButton
                  onClick={() => {
                    nextMessageRead();
                  }}
                  text="Next (Mark Read)"
                  iconProps={{
                    iconName: 'ChevronRightSmall',
                  }}
                />
              )}

              {currentIndex == visibleMessages.length - 1 && (
                <DefaultButton
                  onClick={() => {
                    dismissMessages();
                  }}
                  text="Skip (Read Later)"
                />
              )}

              {currentIndex == visibleMessages.length - 1 && (
                <PrimaryButton
                  onClick={() => {
                    lastMessageClose();
                  }}
                  text="All Done"
                  iconProps={{
                    iconName: 'AcceptMedium',
                  }}
                />
              )}
            </DialogFooter>
          </Dialog>
        )}
      </div>
    </>
  );
}
