import * as React from 'react';
//import styles from '../CduMyApps.module.scss';
import { IApp } from '../Interfaces/IApp';
import { IAppsState } from '../Interfaces/IAppsState';
//import { ICduMyAppsProps } from '../Interfaces/ICduMyAppsProps';
import { Icon } from '@fluentui/react';

export class AppTile extends React.Component<IAppTile, IAppsState> {
  /*constructor(props:IAppTile){
        super(props);
    }*/

  public render(): React.ReactElement<any> {
    //let optional = this.props.app.notes != undefined && this.props.app.notes.indexOf('|OPTIONAL|') > -1 ;
    //if(optional){ console.log("optional",this.props.app.notes);}
    let isVisible =
      this.props.app.visible === undefined || this.props.app.visible || this.props.showHidden;

    //if( optional && this.props.app.visible === undefined ){
    //    isVisible = false;
    //}

    if (this.props.forceVisible) {
      isVisible = true;
    }

    //if(optional){ console.log("optional",this.props.app.notes);console.log("isVisible",isVisible);}

    let hiddenButShown =
      !this.props.app.visible && this.props.app.visible !== undefined && this.props.showHidden;
    let loginURL = this.props.app.loginUrl;
    //let loginURL = `javascript:(window.open('${this.props.app.loginUrl}','_blank'))`;

    let title = this.props.app.displayName;

    try {
      let notes = JSON.parse(this.props.app.notes);

      if (
        notes !== undefined &&
        notes !== null &&
        notes.Description !== undefined &&
        notes.Description !== null &&
        notes.Description.length > 0
      ) {
        //console.log('notes',notes);
        title = notes.Description;
      }
    } catch (err) {
      console.log('Error getting app notes description', err);
    }

    //let title = notes.Description || this.props.app.displayName;

    return (
      <div
        className="appTile"
        style={{ display: isVisible ? '' : 'none', opacity: hiddenButShown ? '0.6' : '' }}
        title={title}
      >
        {
          //<a href={loginURL} >
        }

        <a href={loginURL} data-interception="off" target="_blank" rel="noreferrer">
          {hiddenButShown && (
            <Icon
              iconName="Hide"
              style={{
                color: '#AAA',
                fontSize: '2em',
                position: 'absolute',
                right: '10px',
                top: '0px',
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '4px',
              }}
            ></Icon>
          )}
          <img src={this.props.app.info.logoUrl} alt={this.props.app.displayName} />
          <div className="appIconTitle">{this.props.app.displayName}</div>
        </a>
      </div>
    );
  }
}

export interface IAppTile {
  app: IApp;
  showHidden: boolean;
  forceVisible?: boolean;
}
