import React, { useState, useEffect, useRef } from 'react';
import { msalInstance } from '..';
import _Debug from '../Helper/Debug';

/**
 * This hook component is used to verify whether a student or non-student by email
 */
export default function useUserRole() {
  const [userRole, setUserRole] = useState<any>();
  const [userPrincipalName, setUserPrincipalName] = useState<any>();

  async function fetchUserInfo() {
    let result = await msalInstance.acquireTokenSilent({
      scopes: ['User.Read', 'Directory.AccessAsUser.All'],
    });

    if (result.accessToken) {
      var headers = new Headers();
      var bearer = 'Bearer ' + result.accessToken;
      headers.append('Authorization', bearer);

      var options = {
        method: 'GET',
        headers: headers,
      };

      const userGraph = await fetch(
        'https://graph.microsoft.com/v1.0/me?$select=userPrincipalName',
        options,
      );
      const userFetchedDetails = await userGraph.json();
      // check if userPrincipalName contains cdu student domain name
      const currentUserRole = (userFetchedDetails?.userPrincipalName).includes(
        'students.cdu.edu.au',
      )
        ? 'students'
        : 'non-students';
      // set student role name
      setUserRole(currentUserRole);
      setUserPrincipalName(userFetchedDetails?.userPrincipalName);
    }
  }

  useEffect(() => {
    fetchUserInfo();
  }, []);

  _Debug('User role', userRole, userPrincipalName);

  return [userRole, userPrincipalName];
}
