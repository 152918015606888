import React, { useEffect, useState } from 'react'
import { IApp } from '../Interfaces/IApp';
import { ReactSortable } from 'react-sortablejs';
import { IconButton } from '@fluentui/react';
import { AppTile } from './AppTile';

interface AppSortableEditProps{
    apps:IApp[];
    group:string;
    regionId:string;
    onSortChange?:(apps:IApp[])=>void;
    onSetList?:(apps:IApp[]) => IApp[];
    onPinnedToggle?:(appid:string) => void;
    
}


const AppSortable = (props:AppSortableEditProps) => {
        const {apps,group,regionId,onSortChange,onSetList,onPinnedToggle} = props;
    
        const [localApps,setLocalApps] = useState(apps);
        
    
      useEffect(()=>{setLocalApps(apps)},[apps])
    
      const handleHideToggle = (id:string)=>{
       
        const apps = [...localApps];
        const ind = apps.findIndex(app => app.id === id)
        const visible = apps[ind].visible !== false;
        apps[ind].visible = !visible;
        setLocalApps(apps)
      }

      const handleTogglePin = (id:string)=>{
        onPinnedToggle?.(id);
      }
    
      return (
        <ReactSortable
        group={group}
        list={localApps}
        className="editReactSortable"
        id={regionId}
        ghostClass="dropGhost"
        animation={150}
        //on={(res:any)=>console.log("Updated",res)}
        
        setList={(newState: any) => {
          const updated = onSetList?.(newState) || newState;
          setLocalApps(updated);
          //onSortChange(updated);
        }}
        >
            {localApps.map(app => 
                <TileEdit  app={app} key={app.id} onTogglePin={()=>handleTogglePin(app.id)} onToggleVisible={()=>{handleHideToggle(app.id)}}
            />)}
        </ReactSortable>
      )
    
        
}

export default AppSortable;


interface TileEditProps{
    app:IApp;
    onTogglePin?:()=>void;
    onToggleVisible?:()=>void;
}

const TileEdit = (props:TileEditProps)=>{
    const {app,onTogglePin,onToggleVisible} = props;


    return (<div
            className="appTileWrapper"
            title={app.displayName}
            style={{
              opacity: app.visible === undefined || app.visible ? '1' : '0.5',
              cursor: 'grab',
            }}
            data-id={app.id}
            key={app.id}
          >
            <AppTile app={app} showHidden={true}>
              {' '}
            </AppTile>
            <div className="editIconOverlay">
              <IconButton
                className="tileMenuButton"
                onClick={() => {
                  //console.log("Open Context");
                }}
                menuProps={{
                  items: [
                    {
                      key: 'pinTile',
                      text: app.pinned ? 'Unpin' : 'Pin',
                      iconProps: { iconName: app.pinned ? 'UnPin' : 'Pin' },
                      onClick: () => {
                        onTogglePin?.();
                      },
                    },
                    {
                      key: 'toggleVisible',
                      text: app.visible || app.visible === undefined ? 'Hide' : 'Show', //'Hide/Show',
                      iconProps: {
                        iconName: app.visible || app.visible === undefined ? 'Hide' : 'RedEye',
                      }, //{ iconName: 'Hide' },
                      onClick: () => {
                        onToggleVisible?.();
                      },
                    },
                  ],
                  directionalHintFixed: false,
                }}
                iconProps={{ iconName: 'MoreVertical' }}
                title="Edit"
                ariaLabel="Edit"
                menuIconProps={{ style: { display: 'none' } }}
              ></IconButton>
            </div>
          </div>)
}