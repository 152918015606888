import {
  CommandButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  Icon,
  PrimaryButton,
  DialogType,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { AppSortableGrid } from './AppSortableGrid';
import AppSortable from './AppSortable';
import { IApp } from '../Interfaces/IApp';

export default function EditUserApps(props: any) {
  const [pinnedSortableList, setPinnedSortableList] = useState<IApp[]>();
  const [sortableList, setSortableList] = useState<IApp[]>();
  const [updatedApps, setUpdatedApps] = useState<IApp[]>();
  const [restoreDialog, setRestoreDialog] = useState(false);
  //const userApps = props.apps;

  useEffect(() => {
    // console.log('sortableList',sortableList)

    if (props.apps) {
      setUpdatedApps(props.apps);
      /*setPinnedSortableList(props.apps.filter((item:any)=>{return item.pinned;}));

        setSortableList(props.apps.filter((item:any)=>{return !item.pinned;}));*/
    }
    /*  props.apps.then(
            (res:any)=>{

                console.log(res);
                setPinnedSortableList(res.filter((item:any)=>{return item.pinned;}));

                setSortableList(res.filter((item:any)=>{return !item.pinned;}));
                })
*/
  }, [props.apps]);

  useEffect(() => {
    // console.log('Updated apps Updated');
    if (updatedApps) {
      setPinnedSortableList(
        updatedApps.filter((item: any) => {
          return item.pinned;
        }),
      );

      setSortableList(
        updatedApps.filter((item: any) => {
          return !item.pinned;
        }),
      );
    }
    return () => {};
  }, [updatedApps]);

  const saveSortChanges = () => {
    props.saveEditChanges(updatedApps);
  };

  const restorePrompt = () => {
    setRestoreDialog(true);
    //props.restoreFromBackup();
  };

  const undoSortChanges = () => {
    setUpdatedApps(props.apps);
  };

  const canceEditMode = () => {
    props.toggleEditMode();
  };

  const updatePinnedSortable = (list: any) => {
    const fullList = sortableList?.concat(list);
    setUpdatedApps(fullList);
    /* this.setState({
          editList : fullList,
          pinnedSortableList:fullList.filter((item) => {return item.pinned;}),
          sortableList:fullList.filter((item) => {return !item.pinned;})
        });*/
  };

  const updateSortable = (list: any) => {
    const fullList = pinnedSortableList?.concat(list);
    setUpdatedApps(fullList);
    // console.log('Changed SortedList');
    /*     this.setState({
              editList : fullList,
              pinnedSortableList:fullList.filter((item) => {return item.pinned;}),
              sortableList:fullList.filter((item) => {return !item.pinned;})
        });*/
  };

  //let sortableList:any[] = [];

  //let pinnedSortableList:any[] = props.apps.filter((item:any)=>{return item.pinned;});

  //console.log('edit apps props',sortableList);

  const handlePinToggle = (id:string) =>{
    
    const dupe = updatedApps ? [...updatedApps] : [];
    const appInd = dupe?.findIndex(app => app.id === id);
    if (appInd > -1){
      dupe[appInd].pinned = !dupe[appInd].pinned;
      setUpdatedApps(dupe)
    }

  }

  return (
    <div className="editAppsWrapper">
      <div className="appsMenuContainer">
        <CommandButton
          id="saveAndClose"
          onClick={saveSortChanges}
          iconProps={{ iconName: 'SaveAndClose' }}
          text="Save and Close"
        ></CommandButton>
        <CommandButton
          id="restoreBackup"
          onClick={restorePrompt}
          iconProps={{ iconName: 'CloudDownload' }}
          text="Restore"
        ></CommandButton>
        <CommandButton
          onClick={undoSortChanges}
          iconProps={{ iconName: 'Undo' }}
          text="Undo Sorting"
        ></CommandButton>
        <CommandButton
          onClick={canceEditMode}
          iconProps={{ iconName: 'Cancel' }}
          title="Cancel"
          text="Cancel"
          ariaLabel="Cancel"
        ></CommandButton>
      </div>

      <>
        <div className="appsWrapper" style={{ padding: '20px' }}>
          <div className="pinnedContainer">
            <div style={{ color: 'black', width: '100%' }}>
              <h2 style={{ color: '#AAA', textAlign: 'center' }}>Edit Mode</h2>
              <p style={{ padding: '10px', backgroundColor: '#EEE' }}>
                To sort your apps drag and drop them. To Pin apps Drag them into the pinned area at
                the top.
                <br />
                To hide/show apps click on the menu icon at the top right corner of the app tile.
                <br />
              </p>
            </div>
            {pinnedSortableList && (
              <>
                <Icon
                  iconName="Pinned"
                  style={{ color: '#DDD', fontSize: '2em', position: 'absolute', left: '0px' }}
                ></Icon>
                <AppSortable 
                  group="pinned"
                  regionId="pinned" 
                  apps={updatedApps?.filter((app:any) => app.pinned) || []}
                  onSetList={(apps:IApp[])=>{
                    const pinnedApps = apps.map(app=>({...app,pinned:true}));
                    updatePinnedSortable(pinnedApps);
                    
                    //console.log("Pinned",pinnedApps);
                    return pinnedApps;}}
                  onPinnedToggle={handlePinToggle}
                ></AppSortable>
                {/* <AppSortableGrid
                  group="pinned"
                  regionId="pinned"
                  value={pinnedSortableList}
                  onSortChange={updatePinnedSortable}
                ></AppSortableGrid> */}
              </>
            )}
          </div>
          {sortableList && (
            <div>
              <AppSortable 
                group="pinned"
                regionId="unPinned" 
                apps={updatedApps?.filter((app:any) => !app.pinned) || []} 
                onSetList={(apps:IApp[])=>{
                  const unpinnedApps = apps.map(app=>({...app,pinned:false}));
                  //console.log("Unpinned",unpinnedApps);
                  updateSortable(unpinnedApps);
                  return unpinnedApps;
                  }} 
                onPinnedToggle={handlePinToggle}
              ></AppSortable>
              {/* <AppSortableGrid
                group="pinned"
                regionId="unPinned"
                //value={sortableList}
                value={sortableList.filter((app:any) => !app.pinned)}
                onSortChange={updateSortable}
              ></AppSortableGrid> */}
            </div>
          )}

          {restoreDialog && (
            <Dialog
              onDismiss={() => {
                setRestoreDialog(false);
              }}
              hidden={!restoreDialog}
              dialogContentProps={{
                title: 'Restore Settings?',
                subText:
                  'Are you sure you would like to attempt to restore your settings from a previous saved file?',
                type: DialogType.close,
              }}
            >
              <DialogFooter>
                <DefaultButton
                  onClick={() => {
                    setRestoreDialog(false);
                  }}
                >
                  Cancel
                </DefaultButton>
                <PrimaryButton
                  onClick={() => {
                    props.restoreFromBackup();
                  }}
                >
                  Restore
                </PrimaryButton>
              </DialogFooter>
            </Dialog>
          )}
        </div>
      </>
    </div>
  );
}
