import _Debug from '../Helper/Debug';
import useGraph from '../Hooks/useGraph';
/**
 * This component is used for validating a student then redirect to Student Portal website.
 
 */

// Any department in this array will cancel the redirect.
const ignoredDepartments = ['Alumni'];

export default function StudentRedirect(props: any) {
  const userDetails: { department: string } | undefined = useGraph(
    'https://graph.microsoft.com/v1.0/me?$select=displayName,givenName,surname,userPrincipalName,mail,mobilePhone,id,businessPhone,companyName,department',
  );
  let cancelRedirect = true;

  if (
    userDetails
    // userDetails &&
    // typeof userDetails === 'object' &&
    // userDetails != null &&
    // 'department' in userDetails
  ) {
    const department = userDetails.department as string;
    if (ignoredDepartments.indexOf(department) > -1) {
      cancelRedirect = true;
    } else {
      cancelRedirect = false;
    }
  }

  const studentRedirectSite = 'https://charlesdarwinuni.sharepoint.com/teams/StudentPortal';
  const redirectToWebsite = () => {
    if (!cancelRedirect) window.open(studentRedirectSite, '_self');
  };

  const userPrincipalName = props?.userPrincipalName;
  // Regex to check CDU student principle name with pattern s and 6 digits or more and student domain name
  // For example: s123456@students.cdu.edu.au or s12345678@students.cdu.edu.au
  const studentRegex = /^[sS]\d{6,}@students\.cdu\.edu\.au$/;
  const isStudent = studentRegex.test(userPrincipalName);
  // console.log('is Student', isStudent);

  if (isStudent) {
    setTimeout(() => {
      redirectToWebsite();
    }, 0);
  }
  // if (isStudent) {
  //   return (
  //     <div style={{ backgroundColor: '#F0F8FF', padding: '10px' }}>
  //       <p>
  //         Hello student, this site is redirected to Student Portal.{' '}
  //         <button onClick={redirectToWebsite}>Let's go!</button>
  //       </p>
  //     </div>
  //   );
  // } else {
  //   return null;
  // }
  return null;
}
